import React from "react"
import { Component } from "react";

class LandingArticle extends Component{

    constructor(props){
        super(props);

        this.textMaxLetters = 800;
        this.invisibleTextRef = React.createRef();
    }

    ShowMore(e){
        if (this.invisibleTextRef.current.classList.contains('invisible-text')){
            e.target.innerText = "Roll up";
            this.invisibleTextRef.current.classList.remove('invisible-text')
            this.invisibleTextRef.current.after(e.target)
        } else {
            e.target.innerText = "... Read more";
            this.invisibleTextRef.current.classList.add('invisible-text');
            this.invisibleTextRef.current.before(e.target)
        }
    }

    CreateNewLine(){
        return( <br/> )
    }

    CreateInvisibleText(words){
        return(
            <span className="invisible-text" ref={this.invisibleTextRef}>
                { words }
            </span>
        )
    }

    CreateReadMoreBtn(){
        return (
            <button className="read-more-btn text-primary-dark" onClick={(e) => this.ShowMore(e)}>... Read More</button>
        )
    }

    CreateText(text, textMaxLetters){
        let result = [];
        let currentLetterCount = 0;
        let visibleTextArray = [];
        let invisibleTextArray = [];

        text.split("\n").map(par => {
            if (par.trim() !== ''){
                let visibleText = '';
                let invisibleText = ''

                par.split('').map(letter => {
                    if (currentLetterCount++ < textMaxLetters){
                        visibleText += letter;
                    } else {
                        invisibleText += letter;
                    }
                })

                if (visibleText.length > 0){
                    visibleTextArray.push(visibleText)

                    if (invisibleText.length === 0){
                        visibleTextArray.push(this.CreateNewLine());
                        visibleTextArray.push(this.CreateNewLine());
                    }
                }
                if (invisibleText.length > 0){
                    invisibleTextArray.push(invisibleText);
                    invisibleTextArray.push(this.CreateNewLine());
                    invisibleTextArray.push(this.CreateNewLine());
                }
            }
        });

        result.push(visibleTextArray);

        if (invisibleTextArray.length > 0){
            result.push(this.CreateReadMoreBtn());
            result.push(this.CreateInvisibleText(invisibleTextArray));
        }

        return result;
    }

    render(){
        return(
            <article on className="article-item pb-6 pt-6">
                <h2 className="home_title text-primary-dark font-weight-800 letter-spacing-n2 mb-6">{this.props.item.title ?? ""}</h2>
                <div className="article-context">
                    <div className="article-img-wrapper">
                        {<img className="article-img" src={this.props.item.image?.mediaItemUrl ?? ""} alt={this.props.item.image?.altText ?? ""}/>}
                    </div>
                    <div className="article-desc">
                        <div className="article-desc-text">
                            <p className="article-pg">
                            {
                                this.CreateText(this.props.item?.body, this.textMaxLetters)
                            }
                            </p>
                        </div>
                    </div>
                </div>
            </article>
        )
    }
}

export default LandingArticle
