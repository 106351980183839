import React from "react"
import {Row, Col, Container, Jumbotron} from "react-bootstrap"
import SEO from "../components/seo"
import CovidMessage from "../components/covidMessage";
import Layout from "../components/layout";
import {graphql} from "gatsby";
import CTAForm from "../components/ctaForm";
import LandingArticle from "../components/LandingArticle";

const LandingPage = ({data}) => {

    return (

            <Layout pageInfo={{pageName: "landing"}}>

                <SEO title={data?.post?.metaForPage?.title} description={data?.post?.metaForPage?.description}/>

                <CovidMessage></CovidMessage>

                <Jumbotron id="home" className="bg-primary pt-lg-7 pb-lg-6 text-center mb-0 bg-tyre px-0">
                    <Container className="position-relative">
                        <h1 className="lp-header display-2 font-weight-700 mb-4 text-white text-center">{data?.post?.page_header?.pageTitle}</h1>

                        <CTAForm CTAButtonText={data?.post?.landing_page?.ctabuttontext}></CTAForm>

                        <Row className="mt-6">
                            <Col lg={3} className="mb-4 mb-lg-0">
                                <Row>
                                    <Col xs={12} className="text-left">
                                        <h3 className="h4 font-weight-900 text-white mb-2">{data?.post?.page_header?.feature1Title ?? ""}</h3>
                                        <p className="text-white mb-0">
                                            {data?.post?.page_header?.feature1Text ?? ""}
                                        </p>
                                    </Col>
                                </Row>
                            </Col>
                            <Col lg={3} className="mb-4 mb-lg-0">
                                <Row>
                                    <Col xs={12} className="text-left">
                                        <h3 className="h4 font-weight-900 text-white mb-2">{data?.post?.page_header?.feature2Title ?? ""}</h3>
                                        <p className="text-white mb-0">
                                            {data?.post?.page_header?.feature2Text ?? ""}
                                        </p>
                                    </Col>
                                </Row>
                            </Col>
                            <Col lg={3} className="mb-4 mb-lg-0">
                                <Row>
                                    <Col xs={12} className="text-left">
                                        <h3 className="h4 font-weight-900 text-white mb-2">{data?.post?.page_header?.feature3Title ?? ""}</h3>
                                        <p className="text-white mb-0">
                                            {data?.post?.page_header?.feature3Text ?? ""}
                                        </p>
                                    </Col>
                                </Row>
                            </Col>
                            <Col lg={3} className="mb-4 mb-lg-0">
                                <Row>
                                    <Col xs={12} className="text-left">
                                        <h3 className="h4 font-weight-900 text-white mb-2">{data?.post?.page_header?.feature4Title ?? ""}</h3>
                                        <p className="text-white mb-0">
                                            {data?.post?.page_header?.feature4Text ?? ""}
                                        </p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </Jumbotron>

                <Container className="home-step mt-6 mb-3" id="howtosell">
                    <h2 className="home_title text-primary-dark font-weight-800 letter-spacing-n2">How to sell your car with iCashCars</h2>
                    <ul className="steps-list mt-3 justify-content-lg-start justify-content-sm-center">
                        {data?.post?.steps?.steps?.map((step, i) => (
                            <li className="step-item mb-4 col-lg-4 col-sm-10">

                                <div className="step-img-wrapper">
                                    <p className="step-name zindex-100 text-gray font-weight-900">Step <span className="text-primary-dark">{step.step ?? i}</span></p>
                                    {<img className="step-img" src={step.stepImage?.mediaItemUrl ?? ""} alt={step.stepImage?.altText ?? ""}/>}
                                </div>
                                <div className="step-text mt-3">
                                <p className="mb-1">{step.text ?? ""}</p>
                            </div>
                            </li>
                        )) ?? ""}
                    </ul>
                </Container>

                <Container>
                    <section className="articles-list mt-5 mb-5">
                        {data?.post?.landing_page?.contentBlocks?.map((item, i) => (
                            <LandingArticle item={item}></LandingArticle>
                        ))}
                    </section>
                </Container>
            </Layout>

    )
}

export const data = graphql`
    query ($id: String){
        post: wpLandingPage(id: {eq: $id}) {
            title
            metaForPage {
                description
                title
              }
            page_header {
              feature1Text
              feature1Title
              feature2Text
              feature2Title
              feature3Text
              feature3Title
              feature4Text
              feature4Title
              pageTitle
            }
            landing_page {
                ctabuttontext
                contentBlocks {
                  body
                  title
                  image {
                    altText
                    mediaItemUrl
                    title
                  }
                }
              }
              steps {
                steps {
                  step
                  text
                  stepImage {
                    altText
                    mediaItemUrl
                  }
                }
            }
        }
    }
`
export default LandingPage
